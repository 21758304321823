// extracted by mini-css-extract-plugin
export var Hero = "x_b9";
export var rowA = "x_cb";
export var rowA__01 = "x_cc";
export var rowA__02 = "x_cd";
export var logo = "x_b2";
export var logo__base = "x_cf";
export var logo__text = "x_cg";
export var aniSpin = "x_d";
export var rowA__03 = "x_ch";
export var rowA__03__top = "x_cj";
export var rowA__03__top__left = "x_ck";
export var rowA__03__top__right = "x_cl";
export var rowA__03__bottom = "x_cm";
export var rowA__03__bottom__text = "x_cn";
export var rowA__04 = "x_cp";
export var rowB = "x_cq";
export var rowB__01 = "x_cr";
export var rowB__01__top = "x_cs";
export var rowB__01__bottom = "x_ct";
export var rowB__01__middle = "x_cv";
export var rowB__02 = "x_cw";
export var rowB__03 = "x_cx";
export var rowB__03__container = "x_cy";
export var rowB__03__col = "x_cz";
export var rowB__03__labels = "x_cB";
export var rowB__03__labels__top = "x_cC";
export var rowB__03__labels__middle = "x_cD";
export var rowB__03__labels__bottom = "x_cF";
export var rowC = "x_cG";
export var rowC__01 = "x_cH";
export var rowC__02 = "x_cJ";
export var rowC__02__col = "x_cK";
export var rowC__02__col__container = "x_cL";
export var rowC__02__text = "x_cM";
export var rowC__03 = "x_cN";
export var rowC__04 = "x_cP";
export var rowC__04__side = "x_cQ";
export var rowC__04__middle = "x_cR";
export var rowC__04__image = "x_cS";
export var rowC__04__text = "x_cT";
export var intro = "x_bz";
export var heading = "x_t";
export var window = "x_cV";
export var window__top = "x_cW";
export var window__bottom = "x_cX";
export var window__main = "x_cY";
export var window__main__part = "x_cZ";
export var label = "x_bX";